import axios from "axios";

// Create an instance of brandsAPI with the base URL from .env
const API = axios.create({
  baseURL:
    process.env.REACT_APP_PRODUCTION === "false"
      ? "http://localhost:8080"
      : process.env.REACT_APP_API_URL,
});

export default API;
