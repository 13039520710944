import React, { useEffect, useMemo, useState } from "react";
import CryptoJS from "crypto-js";
import logo from "../assets/images/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faDiamondTurnRight } from "@fortawesome/free-solid-svg-icons";

import API from "../util/api";
import { useNavigate, useParams } from "react-router-dom";

const API_URL =
  process.env.REACT_APP_PRODUCTION === "false"
    ? "http://localhost:8080"
    : process.env.REACT_APP_API_URL.replace("badge", "reviews-badge");

// Encrypt Text
const secretKey = "01234567890123456789012345678901";
const iv = "1234567890123456";

function Widgets() {
  const params = useParams();
  const navigate = useNavigate();
  const companyHash = params?.code;
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [company, setCompany] = useState();
  const [slug, setSlug] = useState();

  useEffect(() => {
    if (params?.code) {
      const decrypted = CryptoJS.AES.decrypt(
        {
          ciphertext: CryptoJS.enc.Hex.parse(params?.code),
        },
        CryptoJS.enc.Utf8.parse(secretKey),
        {
          iv: CryptoJS.enc.Utf8.parse(iv),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        },
      );

      const decryptedText = decrypted.toString(CryptoJS.enc.Utf8) || "";
      if (decryptedText) {
        console.log("Decrypted Text:", decryptedText);
        handleCompanyIDSubmit(decryptedText);
        setSlug(decryptedText);
      } else {
        navigate("/404");
      }
    }
  }, [params?.code]);

  useEffect(() => {
    if (showSnackbar) {
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);
    }
  }, [showSnackbar]);

  const widgetsData = [
    {
      section: "Badges",
      code: `<a href="https://www.consumeraffairs.com/go/cta?url=${company?.original_campaign_url}&element_label=brands_app_widget_click"><img width="200" height="80" src="${API_URL}/${slug}.png?type=1&name=Badge 1" alt="Reviews on ConsumerAffairs" title="Read our reviews on ConsumerAffairs"/></a>`,
      name: "Badge 1",
    },
    {
      section: "Badges",
      code: `<a href="https://www.consumeraffairs.com/go/cta?url=${company?.original_campaign_url}&element_label=brands_app_widget_click"><img width="200" height="100" src="${API_URL}/${slug}.png?type=2&name=Badge 2" alt="Reviews on ConsumerAffairs" title="Read our reviews on ConsumerAffairs"/></a>`,
      name: "Badge 2",
    },
    {
      section: "Badges",
      code: `<a href="https://www.consumeraffairs.com/go/cta?url=${company?.original_campaign_url}&element_label=brands_app_widget_click"><img width="200" height="50"  src="${API_URL}/${slug}.png?type=3&name=Badge 3" alt="Reviews on ConsumerAffairs" title="Read our reviews on ConsumerAffairs"/></a>`,
      name: "Badge 3",
    },
    {
      section: "Badges",
      code: `<a href="https://www.consumeraffairs.com/go/cta?url=${company?.original_campaign_url}&element_label=brands_app_widget_click"><img width="450" height="280" src="${API_URL}/${slug}.png?type=4&name=Badge 4"  alt="Reviews on ConsumerAffairs" title="Read our reviews on ConsumerAffairs"/></a>`,
      name: "Badge 4",
    },
    {
      section: "Badges",
      code: `<a href="https://www.consumeraffairs.com/go/cta?url=${company?.original_campaign_url}&element_label=brands_app_widget_click"><img width="200" height="80"  src="${API_URL}/${slug}.png?type=6&name=Badge 5" alt="Reviews on ConsumerAffairs" title="Read our reviews on ConsumerAffairs"/></a>`,
      name: "Badge 5",
    },
    {
      section: "Badges",
      code: `<a href="https://www.consumeraffairs.com/go/cta?url=${company?.original_campaign_url}&element_label=brands_app_widget_click"><img width="200" height="80" src="${API_URL}/${slug}.png?type=7&name=Badge 6" alt="Reviews on ConsumerAffairs" title="Read our reviews on ConsumerAffairs"/></a>`,
      name: "Badge 6",
    },
    {
      section: "Badges",
      code: `<a href="https://www.consumeraffairs.com/go/cta?url=${company?.original_campaign_url}&element_label=brands_app_widget_click"><img width="200" height="80" src="${API_URL}/${slug}.png?type=8&name=Badge 7" alt="Reviews on ConsumerAffairs" title="Read our reviews on ConsumerAffairs"/></a>`,
      name: "Badge 7",
    },
    {
      section: "Badges",
      code: `<a href="https://www.consumeraffairs.com/go/cta?url=${company?.original_campaign_url}&element_label=brands_app_widget_click"><img width="200" height="80" src="${API_URL}/${slug}.png?type=9&name=Badge 8" alt="Reviews on ConsumerAffairs" title="Read our reviews on ConsumerAffairs"/></a>`,
      name: "Badge 8",
    },
    {
      section: "Badges",
      code: `<a href="https://www.consumeraffairs.com/go/cta?url=${company?.original_campaign_url}&element_label=brands_app_widget_click"><img width="200" height="80" src="${API_URL}/${slug}.png?type=10&name=Badge 9" alt="Reviews on ConsumerAffairs" title="Read our reviews on ConsumerAffairs"/></a>`,
      name: "Badge 9",
    },
    {
      section: "Email Signature",
      link: company?.original_campaign_url,
      imgSrc: `${API_URL}/${companyHash}.png?type=5`,
      code: `<a href="https://www.consumeraffairs.com/go/cta?url=${company?.original_campaign_url}&element_label=brands_app_widget_click"><img width="200" height="90" src="${API_URL}/${slug}.png?type=5&name=Signature 1" alt="Reviews on ConsumerAffairs" title="Read our reviews on ConsumerAffairs"/></a>`,
      name: "Signature 1",
    },
    // Add more widgets as needed
  ];

  const handleCompanyIDSubmit = async (slug) => {
    try {
      const companyResponse = (
        await API.get(`/company?slug=${slug}`, {
          headers: {
            Authorization:
              "Token a683ae663041f69aa64b506bd650b4ed2bf20546b8698a842d4d083cffd70de8",
          },
        })
      )?.data;
      console.log("Company response:", companyResponse);
      setCompany(companyResponse);
    } catch (error) {
      alert("Invalid company ID");
    }
    setShowSnackbar(false); // Reset the snackbar state
  };

  const handleCopyCode = (code) => {
    navigator.clipboard.writeText(code);
    setShowSnackbar(true);
  };

  const copyCodeToClipboard = (code) => {
    navigator.clipboard.writeText(code);
    handleCopyCode(code);
  };

  const openBadgeInTheNewTab = (title, htmlCode) => {
    const newTab = window.open();
    newTab.document.write(htmlCode);
    newTab.document.title = title;
    newTab.document.close();
  };

  const sections = Array.from(
    new Set(widgetsData.map((widget) => widget.section)),
  );

  return (
    <div className="font-sans antialiased bg-gray-100 flex flex-col min-h-screen">
      <header className="bg-white shadow">
        <nav className="container mx-auto px-4 py-6 flex items-center justify-between">
          <div>
            <a
              href="https://ConsumerAffairs.com "
              className="text-2xl font-bold text-gray-800"
            >
              <img
                src={logo}
                alt="Consumer Affairs"
                className="h-8 object-contain"
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.consumeraffairs.com/"
              className="text-black hover:text-gray-800 ml-6 sm:pr-2 md:px-4"
            >
              Home
            </a>
            <a
              href="https://support.google.com/mail/answer/8395"
              className="text-black hover:text-gray-800 px-2 md:px-4"
            >
              Gmail
            </a>
            <a
              href="https://support.microsoft.com/en-gb/office/create-and-add-an-email-signature-in-outlook-com-776d9006-abdf-444e-b5b7-a61821dff034"
              className="text-black hover:text-gray-800 px-2 md:px-4"
            >
              Outlook
            </a>
          </div>
        </nav>
      </header>
      <main className="container mx-auto px-4 py-6 flex-grow">
        <h1 className="text-xl md:text-4xl font-bold text-black mb-2 md:mb-8 flex flex-row h-8">
          {company?.name}
        </h1>
        {sections.map((section) => (
          <section key={section} className="mb-8">
            <h2 className="text-lg md:text-xl font-medium text-gray-600 mb-4">
              {section}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {widgetsData
                .filter((widget) => widget.section === section)
                .map((widget) => (
                  <div
                    key={widget?.name}
                    className="bg-white rounded shadow-md p-6 flex flex-col items-center"
                  >
                    <span className="mb-3 font-bold underline underline-offset-4">
                      {widget.name}
                    </span>
                    <div
                      className="max-w-xs justify-center mb-4 flex"
                      dangerouslySetInnerHTML={{ __html: widget.code }}
                    />
                    {!widget?.imgSrc ? (
                      <div className="bg-gray-100 rounded-md p-4 pt-3 w-full">
                        <code className="text-gray-600 text-xs break-words">
                          {widget.code}
                        </code>
                      </div>
                    ) : null}
                    {!widget?.imgSrc ? (
                      <div className="flex justify-center mt-4">
                        <button
                          className="bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-hover flex items-center font-medium focus:shadow-md"
                          onClick={() => copyCodeToClipboard(widget.code)}
                        >
                          <FontAwesomeIcon icon={faCopy} className="mr-2" />
                          Copy code
                        </button>
                      </div>
                    ) : (
                      <div className="flex justify-center flex-col mt-4">
                        <button
                          className="bg-blue-500 text-white mt-3 px-6 py-2 rounded-full hover:bg-hover flex items-center font-medium focus:shadow-md"
                          onClick={() =>
                            openBadgeInTheNewTab(widget.name, widget.code)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faDiamondTurnRight}
                            className="mr-2"
                          />
                          Open widget
                        </button>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </section>
        ))}
      </main>
      <footer className="bg-black py-6">
        <div className="container mx-auto text-center text-white">
          <p>&copy; 2024 ConsumerAffairs. All rights reserved.</p>
        </div>
      </footer>
      {showSnackbar ? (
        <div className="fixed bottom-4 right-4 bg-green-600 font-medium text-white px-4 py-2 rounded">
          Successfully copied!
        </div>
      ) : null}
    </div>
  );
}

export default Widgets;
