import React, { useEffect, useRef, useState } from "react";
import logo from "./assets/images/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faArrowLeft,
  faSearch,
  faSpinner,
  faEye,
  faEyeSlash,
  faDiamondTurnRight,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";

import backgroundImage from "./assets/images/hex-pattern_large_left.svg";
import API from "./util/api";

const API_URL =
  process.env.REACT_APP_PRODUCTION === "false"
    ? "http://localhost:8080"
    : process.env.REACT_APP_API_URL.replace("badge", "reviews-badge");

function App() {
  const [companySlug, setCompanySlug] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [navigateToWidgets, setNavigateToWidgets] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState();
  const [companyInputPlaceholder, setCompanyInputPlaceholder] = useState();
  const [pinCode, setPinCode] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    const isAuthenticatedCookie = Cookies.get("isAuthenticated");
    if (
      isAuthenticatedCookie === "true" ||
      process.env.REACT_APP_PRODUCTION === "false"
    ) {
      setIsAuthenticated(true);
    }
  }, []);

  const handlePinCodeSubmit = (e) => {
    e.preventDefault();
    if (pinCode === process.env.REACT_APP_PIN_CODE) {
      setIsAuthenticated(true);
      inputRef.current.value = "";
      Cookies.set("isAuthenticated", true, { expires: 365 });
    } else {
      alert("Invalid PIN code. Please try again.");
    }
  };

  useEffect(() => {
    if (showSnackbar) {
      setTimeout(() => {
        setShowSnackbar(false);
      }, 3000);
    }
  }, [showSnackbar]);

  useEffect(() => {
    if (companySlug?.length === 0) {
      setSuggestions([]);
      setCompanyInputPlaceholder(null);
    }
  }, [companySlug]);

  useEffect(() => {
    if (process.env.REACT_APP_PRODUCTION === "false") {
      setCompanySlug("western-union");
      handleCompanyIDSubmit("western-union");
    }
  }, []);

  // noinspection HtmlRequiredAltAttribute
  const widgetsData = [
    {
      section: "Badges",
      code: `<a href="https://www.consumeraffairs.com/go/cta?url=${company?.original_campaign_url}&element_label=brands_app_widget_click"><img width="200" height="80" src="${API_URL}/${companySlug}.png?type=1&name=Badge 1" alt="Reviews on ConsumerAffairs" title="Read our reviews on ConsumerAffairs"/></a>`,
      name: "Badge 1",
    },
    {
      section: "Badges",
      code: `<a href="https://www.consumeraffairs.com/go/cta?url=${company?.original_campaign_url}&element_label=brands_app_widget_click"><img width="200" height="100" src="${API_URL}/${companySlug}.png?type=2&name=Badge 2" alt="Reviews on ConsumerAffairs" title="Read our reviews on ConsumerAffairs"/></a>`,
      name: "Badge 2",
    },
    {
      section: "Badges",
      code: `<a href="https://www.consumeraffairs.com/go/cta?url=${company?.original_campaign_url}&element_label=brands_app_widget_click"><img width="200" height="50"  src="${API_URL}/${companySlug}.png?type=3&name=Badge 3" alt="Reviews on ConsumerAffairs" title="Read our reviews on ConsumerAffairs"/></a>`,
      name: "Badge 3",
    },
    {
      section: "Badges",
      code: `<a href="https://www.consumeraffairs.com/go/cta?url=${company?.original_campaign_url}&element_label=brands_app_widget_click"><img width="450" height="280" src="${API_URL}/${companySlug}.png?type=4&name=Badge 4"  alt="Reviews on ConsumerAffairs" title="Read our reviews on ConsumerAffairs"/></a>`,
      name: "Badge 4",
    },
    {
      section: "Badges",
      code: `<a href="https://www.consumeraffairs.com/go/cta?url=${company?.original_campaign_url}&element_label=brands_app_widget_click"><img width="200" height="80"  src="${API_URL}/${companySlug}.png?type=6&name=Badge 5" alt="Reviews on ConsumerAffairs" title="Read our reviews on ConsumerAffairs"/></a>`,
      name: "Badge 5",
    },
    {
      section: "Badges",
      code: `<a href="https://www.consumeraffairs.com/go/cta?url=${company?.original_campaign_url}&element_label=brands_app_widget_click"><img width="200" height="80" src="${API_URL}/${companySlug}.png?type=7&name=Badge 6" alt="Reviews on ConsumerAffairs" title="Read our reviews on ConsumerAffairs"/></a>`,
      name: "Badge 6",
    },
    {
      section: "Badges",
      code: `<a href="https://www.consumeraffairs.com/go/cta?url=${company?.original_campaign_url}&element_label=brands_app_widget_click"><img width="200" height="80" src="${API_URL}/${companySlug}.png?type=8&name=Badge 7" alt="Reviews on ConsumerAffairs" title="Read our reviews on ConsumerAffairs"/></a>`,
      name: "Badge 7",
    },
    {
      section: "Badges",
      code: `<a href="https://www.consumeraffairs.com/go/cta?url=${company?.original_campaign_url}&element_label=brands_app_widget_click"><img width="200" height="80" src="${API_URL}/${companySlug}.png?type=9&name=Badge 8" alt="Reviews on ConsumerAffairs" title="Read our reviews on ConsumerAffairs"/></a>`,
      name: "Badge 8",
    },
    {
      section: "Badges",
      code: `<a href="https://www.consumeraffairs.com/go/cta?url=${company?.original_campaign_url}&element_label=brands_app_widget_click"><img width="200" height="80" src="${API_URL}/${companySlug}.png?type=10&name=Badge 9" alt="Reviews on ConsumerAffairs" title="Read our reviews on ConsumerAffairs"/></a>`,
      name: "Badge 9",
    },
    {
      section: "Email Signature",
      link: company?.original_campaign_url,
      imgSrc: `${API_URL}/${companySlug}.png?type=5`,
      code: `<a href="https://www.consumeraffairs.com/go/cta?url=${company?.original_campaign_url}&element_label=brands_app_widget_click"><img width="200" height="90" src="${API_URL}/${companySlug}.png?type=5&name=Signature 1" alt="Reviews on ConsumerAffairs" title="Read our reviews on ConsumerAffairs"/></a>`,
      name: "Signature 1",
    },
    // Add more widgets as needed
  ];

  const handleCompanyIDSubmit = async (slug) => {
    setSuggestions([]);
    setIsLoading(true);
    try {
      const companyResponse = (
        await API.get(`/company?slug=${slug}`, {
          headers: {
            Authorization:
              "Token a683ae663041f69aa64b506bd650b4ed2bf20546b8698a842d4d083cffd70de8",
          },
        })
      )?.data;
      console.log("Company response:", companyResponse);
      setCompany(companyResponse);
      setNavigateToWidgets(true);
    } catch (error) {
      alert("Invalid company ID");
    } finally {
      setIsLoading(false);
    }
    setShowSnackbar(false); // Reset the snackbar state
  };

  const handleCopyCode = (code) => {
    navigator.clipboard.writeText(code);
    setShowSnackbar(true);
  };

  const handleCompanyIDChange = (e) => {
    const value = e.target.value;
    setCompanySlug(value);

    if (value?.length === 0) {
      setSuggestions([]); // Reset the suggestions state
      setCompanyInputPlaceholder(null);
      return;
    }

    // Query the API for suggestions based on the input value
    API.get(`/companies?name=${value}`)
      .then((response) => {
        setSuggestions(response?.data);
      })
      .catch((error) => {
        console.error("Error fetching suggestions:", error);
      });
  };

  const copyCodeToClipboard = (code) => {
    navigator.clipboard.writeText(code);
    handleCopyCode(code);
  };

  const openBadgeInTheNewTab = (title, htmlCode) => {
    const newTab = window.open();
    newTab.document.write(htmlCode);
    newTab.document.title = title;
    newTab.document.close();
  };

  const handleBackButtonClick = () => {
    setNavigateToWidgets(false);
    setCompanySlug(null); // Reset the company ID input field
    setCompanyInputPlaceholder(null);
  };

  const sections = Array.from(
    new Set(widgetsData.map((widget) => widget.section)),
  );

  console.log("External ID:", companySlug);

  return (
    <div className="font-sans antialiased bg-gray-100 flex flex-col min-h-screen">
      <header className="bg-white shadow">
        <nav className="container mx-auto px-4 py-6 flex items-center justify-between">
          <div>
            <a
              href="https://ConsumerAffairs.com "
              className="text-2xl font-bold text-gray-800"
            >
              <img
                src={logo}
                alt="Consumer Affairs"
                className="h-8 object-contain"
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.consumeraffairs.com/"
              className="text-black hover:text-gray-800 ml-6 md:ml-0 pr-2 md:pr-0 md:px-4"
            >
              Home
            </a>
            <a
              href="https://support.google.com/mail/answer/8395"
              className="text-black hover:text-gray-800 px-2 md:px-4"
            >
              Gmail
            </a>
            <a
              href="https://support.microsoft.com/en-gb/office/create-and-add-an-email-signature-in-outlook-com-776d9006-abdf-444e-b5b7-a61821dff034"
              className="text-black hover:text-gray-800 px-2 md:px-4"
            >
              Outlook
            </a>
          </div>
        </nav>
      </header>
      {!isAuthenticated ? (
        <div className="w-screen bg-blue-dark flex flex-grow">
          <div
            className="flex pt-32 sm:pt-0 sm:items-center items-start justify-center flex-grow min-h-screen md:min-h-0"
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "left",
              margin: "0 auto",
            }}
          >
            <form
              onSubmit={handlePinCodeSubmit}
              className="w-screen max-w-md relative"
            >
              <div className="flex">
                <input
                  ref={inputRef}
                  type={showPassword ? "text" : "password"}
                  value={pinCode}
                  onChange={(e) => setPinCode(e.target.value)}
                  placeholder="Enter PIN code"
                  className="w-full px-9 py-5 text-xl text-gray-800 bg-white rounded-full focus:outline-none placeholder-gray-500 font-medium sm:mx-0 mx-8"
                />
                {pinCode ? (
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-[145px] top-1/2 transform -translate-y-1/2 bg-gray-200 w-[40px] h-[40px] rounded-full flex items-center justify-center focus:outline-none"
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </button>
                ) : null}
                <button
                  type="submit"
                  className={`bg-blue-500 w-[150px] text-xl font-medium text-white px-8 py-3 rounded-full hover:bg-hover focus:outline-none ml-2 flex-row items-center focus:shadow-md`}
                >
                  Log In
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : !companySlug || !navigateToWidgets ? (
        <div className="w-screen bg-blue-dark flex flex-grow">
          <div
            className="flex pt-32 sm:pt-0 sm:items-center items-start justify-center flex-grow min-h-screen md:min-h-0"
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "left",
              margin: "0 auto",
            }}
          >
            <form
              onSubmit={() => handleCompanyIDSubmit(companySlug)}
              className="w-screen max-w-2xl relative"
            >
              <div className="flex">
                <input
                  type="text"
                  value={
                    companyInputPlaceholder
                      ? companyInputPlaceholder
                      : companySlug
                  }
                  onChange={handleCompanyIDChange}
                  placeholder="Enter a campaign name"
                  className="w-full px-2 md:px-9 py-2 md:py-5 text-md md:text-xl text-gray-800 bg-white rounded-full focus:outline-none placeholder-gray-500 font-medium mx-2 md:mx-8"
                />
                <button
                  type="submit"
                  className={`bg-blue-500 text-md md:text-xl font-medium text-white px-2 md:px-8 py-1 md:py-3 rounded-full hover:bg-hover focus:outline-none ml-1 md:ml-2 mr-1 md:mr-0 flex-row items-center focus:shadow-md ${
                    isLoading ? "pointer-events-none" : ""
                  }`}
                  onClick={() => handleCompanyIDSubmit(companySlug)}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className={"flex flex-row items-center "}>
                      <FontAwesomeIcon
                        icon={faSpinner}
                        className="text-lg mr-3 animate-spin"
                      />
                      Loading...
                    </div>
                  ) : (
                    <div className={"flex items-center flex-row"}>
                      <FontAwesomeIcon
                        icon={faSearch}
                        className="text-lg mr-1 md:mr-3"
                      />
                      Search
                    </div>
                  )}
                </button>
              </div>

              {/* Render the autocomplete suggestions */}
              {suggestions.length > 0 && (
                <ul className="absolute bg-white border border-gray-300 divide-y divide-gray-200 rounded-md shadow-lg mt-2 w-full">
                  {suggestions.map((company) => (
                    <li
                      key={company.id}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex-row flex items-center"
                      onClick={async () => {
                        setCompanySlug(company.slug);
                        setCompanyInputPlaceholder(company.name);
                        console.log(
                          "Set external company id",
                          company.external_id,
                        );
                        await handleCompanyIDSubmit(company.slug);
                      }}
                    >
                      {company.logo ? (
                        <img
                          key={company.id}
                          src={company.logo}
                          className={"w-16 h-8 object-contain mr-4"}
                        />
                      ) : null}
                      {company.name}
                    </li>
                  ))}
                </ul>
              )}
            </form>
          </div>
        </div>
      ) : (
        <main className="container mx-auto px-4 py-6 flex-grow">
          <div className="mb-8">
            <div className="mb-8 flex items-center">
              <button
                className="bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-hover flex items-center font-medium focus:shadow-md"
                onClick={handleBackButtonClick}
              >
                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                Pick a different company
              </button>
            </div>
          </div>
          <h1 className="text-xl md:text-4xl font-bold text-black mb-2 md:mb-8 flex flex-row h-8">
            {company?.name}
          </h1>
          {sections.map((section) => (
            <section key={section} className="mb-8">
              <h2 className="text-xl font-medium text-gray-600 mb-4">
                {section}
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {widgetsData
                  .filter((widget) => widget.section === section)
                  .map((widget) => (
                    <div className="bg-white rounded shadow-md p-6 flex flex-col items-center">
                      <span className="mb-3 font-bold underline underline-offset-4">
                        {widget.name}
                      </span>
                      <div
                        className="max-w-xs justify-center mb-4 flex"
                        dangerouslySetInnerHTML={{ __html: widget.code }}
                      />
                      {!widget?.imgSrc ? (
                        <div className="bg-gray-100 rounded-md p-4 pt-3 w-full">
                          <code className="text-gray-600 text-xs break-words">
                            {widget.code}
                          </code>
                        </div>
                      ) : null}
                      {!widget?.imgSrc ? (
                        <div className="flex justify-center mt-4">
                          <button
                            className="bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-hover flex items-center font-medium focus:shadow-md"
                            onClick={() => copyCodeToClipboard(widget.code)}
                          >
                            <FontAwesomeIcon icon={faCopy} className="mr-2" />
                            Copy code
                          </button>
                        </div>
                      ) : (
                        <div className="flex justify-center flex-col mt-4">
                          <button
                            className="bg-blue-500 text-white mt-3 px-6 py-2 rounded-full hover:bg-hover flex items-center font-medium focus:shadow-md"
                            onClick={() =>
                              openBadgeInTheNewTab(widget.name, widget.code)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faDiamondTurnRight}
                              className="mr-2"
                            />
                            Open widget
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </section>
          ))}
        </main>
      )}

      <footer className="bg-black py-6">
        <div className="container mx-auto text-center text-white">
          <p>&copy; 2024 ConsumerAffairs. All rights reserved.</p>
        </div>
      </footer>

      {showSnackbar && (
        <div className="fixed bottom-4 right-4 bg-green-600 font-medium text-white px-4 py-2 rounded">
          Successfully copied!
        </div>
      )}
    </div>
  );
}

export default App;
